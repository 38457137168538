<template>
	<div style="display:block" class="layer">
		<div class="layer__dim"></div>
		<div class="layer__full">
			<div class="layer__fullbox">
				<div class="layer__fullheader">
					<nav class="nav">
						<div class="nav__wrap">
							<a href="#" class="nav__btn nav__btn--prev">
								<i class="icon icon-close">쿠폰 적용 닫기</i>
							</a>
							<p class="nav__item">
								<span class="nav__text">
									쿠폰 적용
								</span>
							</p>
							<a href="#" class="nav__btn">
							</a>
						</div>
					</nav>
				</div>
				<div class="layer__fullbody">
					<section class="layer-coupon">
						<div class="box">
							<!-- [D] 일반 쿠폰 -->
							<div class="booking-box">
								<div class="box box--reverse">
									<h4 class="layer-coupon__title">일반 쿠폰</h4>
									<div class="nocoupon">
										사용 가능한 일반쿠폰이 없습니다
									</div>
								</div>
							</div>
							<!-- [D] 중복 쿠폰 -->
							<div class="booking-box">
								<div class="box box--reverse">
									<h4 class="layer-coupon__title">중복 쿠폰</h4>
									<div class="nocoupon">
										사용 가능한 중복쿠폰이 없습니다
									</div>
								</div>
							</div>
						</div>
					</section>
				</div>
			</div>
		</div>
		<div id="navigation" class="navigation navigation--noborder">
			<div class="btns btns--centered">
				<button type="button" class="btn btn-disabled">쿠폰 적용하기</button>
			</div>
		</div>
	</div>
</template>